@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Title {
  font-family: 'Exo 2', sans-serif;
  font-size: 64px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -4px;
  margin-top:1rem;
}

.Quote {
  color: #1b1b1b;
  font-family: 'Exo 2', sans-serif;
  font-size: 32px;
  font-weight: 200;
  line-height: 38px;
}

p.Large {
  color: #1b1b1b;
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: 200;
  line-height: 29px;
  text-align: center;
  a {
    font-size: 24px;
    font-weight: 200;
    line-height: 29px;
    text-decoration:none;
    font-weight: 500;
    color: #572700;
  }
}

.Box {
  width: 60%;
  text-align: center;
  justify-self: center;
}
.App {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}